import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  Button,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  Grow,
  Paper,
  Popper,
  MenuList,
  ClickAwayListener,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { createArticle, getArticleById, getAllProducts, updateArticle } from "../.././services/articleService";
import Auth from "../common/Auth";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const TextAreaContainer = styled.div`
  width: 60%;
  min-height: 40rem;
  height: fit-content;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0;
  padding-top: 40px;
`;
const Container = styled.div`
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  flex-direction: row;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;
const Left = styled.div`
  width: 40%;
  margin-right: 50px;
  @media (max-width: 900px) {
    width: 100%;
    margin-right: 0;
  }
`;

function CreateArticle() {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [image, setImage] = useState(null);

  const [metaTitle, setMetaTitle] = useState("");
  const history = useHistory();
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [author, setAuthor] = useState("");
  const [date, setDate] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [productNames, setProductNames] = useState([]);
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");
  const [title, setTitle] = useState("");
  const [precedence, setPrecedence] = useState(0);
  const [approved, setApproved] = useState(false);
  const [openInstructions, setOpenInstructions] = useState(false);
  const anchorRef = React.useRef(null);

  useEffect(() => {
    const fetchArticle = async () => {
      const productNames = await getAllProducts();
      setProductNames(productNames);
      if (id) {
        const article = await getArticleById(id);

        if (article) {
          setText(article.content);
          setTitle(article.title);
          setAuthor(article.author);
          setSelectedProduct(article.product);
          setSelectedLanguage(article.language);
          setSelectedCountry(article.country);
          setDate(article.publishedDate);
          setIsEditing(true);
          setMetaTitle(article.metaTitle);
          setPrecedence(article.precedence || 0);
          setApproved(article.approved || false);
          setImagePreviewUrl(article.imageUrl);
        } else {
          console.error("No article data in response");
        }
      }
    };
    fetchArticle();
  }, [id]);

  const handleSubmit = async () => {
    const article = {
      title: title,
      image: image,
      imageUrl: imagePreviewUrl,
      content: text,
      author: author,
      product: selectedProduct,
      language: selectedLanguage,
      country: selectedCountry,
      publishedDate: date,
      precedence: precedence,
      approved: approved,
      metaTitle: metaTitle,
    };
    console.log(article);

    try {
      if (isEditing) {
        await updateArticle(id, article);
      } else {
        await createArticle(article);
      }
      alert("Article submitted successfully! Now inform an admin to approve it.");
      history.push("/articles/articleList"); // This line will redirect the user.
    } catch (error) {
      console.error("Failed to submit article:", error);
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      [{ color: [] }, { background: [] }],

      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = ["header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "image", "color"];

  const handleImageChange = (e) => {
    e.preventDefault();
    let file = e.target.files[0];

    // Check for file size
    const maxSize = 400 * 1024; // 400KB in bytes
    if (file.size > maxSize) {
      alert("The uploaded image is too large. Please ensure it's under 400KB.");
      return;
    }

    // max image render size must be: 1000 x 600

    let reader = new FileReader();
    reader.onloadend = () => {
      let img = new Image();
      img.onload = () => {
        // checking for image dimentions and that they are bellow 1000x600
        const maxWidth = 1000;
        const maxHeight = 600;
        if (img.width > maxWidth || img.height > maxHeight) {
          alert("Image dimensions exceed 1000x600 pixels. Please resize the image and try again");
          return;
        }
        setImage(file);
        setImagePreviewUrl(reader.result);
      };
      img.src = reader.result;
    };
    reader.readAsDataURL(file);
  };

  const handleMetaTitleChange = (e) => {
    setMetaTitle(e.target.value);
  };

  const handleClose = () => setOpen(false);

  return (
    <Container>
      <Left>
        <h3>Mandatory Info</h3>
        <FlexContainer>
          <FormControl style={{ width: "45%" }}>
            <InputLabel>Product</InputLabel>
            <Select value={selectedProduct} label="Product" onChange={(e) => setSelectedProduct(e.target.value)}>
              {productNames.map((productName, index) => {
                return (
                  <MenuItem key={index} value={productName}>
                    {productName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl style={{ width: "45%" }}>
            <InputLabel>Language</InputLabel>
            <Select value={selectedLanguage} label="Language" onChange={(e) => setSelectedLanguage(e.target.value)}>
              <MenuItem value="de">Austria</MenuItem>
              <MenuItem value="nl">Belgium</MenuItem>
              <MenuItem value="cz">Czechia</MenuItem>
              <MenuItem value="da">Dansk</MenuItem>
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="fi">Finnish</MenuItem>
              <MenuItem value="fr">French</MenuItem>
              <MenuItem value="de">German</MenuItem>
              <MenuItem value="nl">Netherlands</MenuItem>
              <MenuItem value="no">Norsk</MenuItem>
              <MenuItem value="pl">Polish</MenuItem>
              <MenuItem value="pt">Portuguese</MenuItem>
              <MenuItem value="es">Spanish</MenuItem>
              <MenuItem value="se">Switzerland</MenuItem>
              <MenuItem value="sv">Svenska</MenuItem>
            </Select>
          </FormControl>
          <FormControl style={{ width: "45%" }}>
            <InputLabel>Country</InputLabel>
            <Select value={selectedCountry} label="Country" onChange={(e) => setSelectedCountry(e.target.value)}>
              <MenuItem value="at">
                <span role="img" aria-label="austria">
                  🇦🇹{""}{" "}
                </span>
                Austria
              </MenuItem>
              <MenuItem value="be">
                <span role="img" aria-label="belgium">
                  🇧🇪{""}{" "}
                </span>
                Belgium
              </MenuItem>
              <MenuItem value="cz">
                <span role="img" aria-label="czechia">
                  🇨🇿{""}
                  {""}
                </span>
                Czechia
              </MenuItem>
              <MenuItem value="dk">
                <span role="img" aria-label="denmark">
                  🇩🇰{""}{" "}
                </span>
                Denmark
              </MenuItem>
              <MenuItem value="fi">
                <span role="img" aria-label="finland">
                  🇫🇮{""}{" "}
                </span>
                Finland
              </MenuItem>
              <MenuItem value="fr">
                <span role="img" aria-label="france">
                  🇫🇷{""}{" "}
                </span>
                France
              </MenuItem>
              <MenuItem value="de">
                <span role="img" aria-label="germany">
                  🇩🇪{""}{" "}
                </span>
                Germany
              </MenuItem>
              <MenuItem value="ie">
                <span role="img" aria-label="Ireland">
                  🇮🇪{""} Ireland
                </span>
              </MenuItem>
              <MenuItem value="nl">
                <span role="img" aria-label="netherlands">
                  🇳🇱{""}{" "}
                </span>
                Netherlands
              </MenuItem>
              <MenuItem value="no">
                <span role="img" aria-label="norway">
                  🇳🇴{""}{" "}
                </span>
                Norway
              </MenuItem>
              <MenuItem value="pl">
                <span role="img" aria-label="poland">
                  🇵🇱{""}{" "}
                </span>
                Poland
              </MenuItem>
              <MenuItem value="pt">
                <span role="img" aria-label="portugal">
                  🇵🇹{""}{" "}
                </span>
                Portugal
              </MenuItem>
              <MenuItem value="es">
                <span role="img" aria-label="spain">
                  🇪🇸{""}{" "}
                </span>
                Spain
              </MenuItem>
              <MenuItem value="se">
                <span role="img" aria-label="sweden">
                  🇸🇪{""}{" "}
                </span>
                Sweden
              </MenuItem>
              <MenuItem value="at">
                <span role="img" aria-label="switzerland">
                  🇨🇭{""}{" "}
                </span>
                Switzerland
              </MenuItem>
              <MenuItem value="gb">
                <span role="img" aria-label="United Kingdom">
                  🇬🇧{""} United Kingdom
                </span>
              </MenuItem>
            </Select>
          </FormControl>
        </FlexContainer>
        <TextField fullWidth label="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
        <TextField style={{ marginTop: "10px" }} fullWidth label="Author" value={author} onChange={(e) => setAuthor(e.target.value)} />
        <FlexContainer>
          <Tooltip title="Type in a concise, URL-friendly slug for your article. This typically resembles the title but is formatted like a URL segment">
            <TextField style={{ width: "50%" }} fullWidth label="Slug" />
          </Tooltip>
          <Tooltip title="Give the page a meta title, this means to give a small description of what the content of the article is">
            <TextField style={{ width: "50%" }} label="Meta" type="text" id="metaTitle" name="metaTitle" value={metaTitle} onChange={handleMetaTitleChange} />
          </Tooltip>
        </FlexContainer>
        <FlexContainer style={{ marginTop: "20px" }}>
          <Tooltip className="theme-color" title="Here you can choose what order to take precedence. 1=first, rest = i++">
            <TextField className="theme-color" style={{ width: "30%" }} fullWidth label="" value={precedence} onChange={(e) => setPrecedence(e.target.value)} type="number" />
          </Tooltip>
          <Tooltip className="theme-color" title="The date will show as DD-MM-YYYY in the application">
            <TextField className="theme-color" style={{ width: "30%" }} label="" type="date" value={date.split("T")[0]} onChange={(e) => setDate(e.target.value)} />
          </Tooltip>
          <Tooltip title="Image must be bellow 400kb. The image wil show above the article">
            <input className="theme-color" style={{ width: "30%" }} type="file" onChange={(e) => handleImageChange(e)} />
          </Tooltip>
        </FlexContainer>
        <img src={imagePreviewUrl} alt="Preview" style={{ width: "100%", height: "400px", objectFit: "cover", backgroundColor: "gray", marginTop: "20px", borderRadius: "5px" }} />
      </Left>
      <TextAreaContainer>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h3>Article Content</h3>
          <Tooltip title="Click to see a list of instructions">
            <InfoOutlinedIcon style={{ marginLeft: "8px", cursor: "pointer" }} ref={anchorRef} onClick={() => setOpenInstructions((prevOpen) => !prevOpen)} />
          </Tooltip>
          <Popper style={{ zIndex: "40" }} open={openInstructions} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}>
                <Paper>
                  <ClickAwayListener onClickAway={() => setOpenInstructions(false)}>
                    <MenuList className="theme-color" style={{ padding: "1rem" }} autoFocusItem={openInstructions} id="menu-list-grow">
                      <li onClick={() => setOpenInstructions(false)}>
                        <strong>Product:</strong>
                        <br />
                        - Click on the dropdown menu under "Product".
                        <br />- Select the relevant product that your article is associated with from the dropdown list.
                      </li>
                      <li onClick={() => setOpenInstructions(false)}>
                        <strong>Language:</strong>
                        <br />
                        - Click on the dropdown menu under "Language".
                        <br />- Choose the language in which you've written your article.
                      </li>
                      <li onClick={() => setOpenInstructions(false)}>
                        <strong>Country:</strong>
                        <br />
                        - Click on the dropdown menu under "Country".
                        <br />- Select the primary target country for your article.
                      </li>
                      <li onClick={() => setOpenInstructions(false)}>
                        <strong>Title:</strong>
                        <br />
                        - Enter the title of your article in the "Title" field.
                        <br />- This will apear on the article page.
                      </li>
                      <li onClick={() => setOpenInstructions(false)}>
                        <strong>Author:</strong>
                        <br />- Enter your name in the "Author" field. (This is usually just "Norwegian Lab")
                      </li>
                      <li onClick={() => setOpenInstructions(false)}>
                        <strong>Slug:</strong> <br /> - Type in a concise, URL-friendly slug for your article. This typically resembles the title but is formatted like a URL
                        segment (e.g., "how-to-use-cms").
                      </li>
                      <li onClick={() => setOpenInstructions(false)}>
                        <strong>Precedence:</strong>
                        <br />- Choose a precedence number for your article. This determines the order of appearance, with "1" being the first.
                      </li>
                      <li onClick={() => setOpenInstructions(false)}>
                        <strong>Date:</strong>
                        <br />- Select the date of publishing using the date picker.
                      </li>
                      <li onClick={() => setOpenInstructions(false)}>
                        <strong>Image:</strong>
                        <br />
                        - Click on the file input field.
                        <br />- Select an image from your device that you'd like to accompany your article. This could be a banner, a thumbnail, or any relevant image.
                      </li>
                      <li onClick={() => setOpenInstructions(false)}>
                        <strong>Draft Your Article</strong>
                        <br />
                        - Below the "Article Content" header, you'll find a rich-text editor.
                        <br />
                        - Start typing or pasting your content.
                        <br />- Use the toolbar at the top of the editor for formatting options like headers, bolding, italicizing, adding links, images, etc.
                      </li>
                      <li onClick={() => setOpenInstructions(false)}>
                        <strong>Preview article and test / check</strong>
                      </li>
                      <li onClick={() => setOpenInstructions(false)}>
                        <strong>Submit Your Article</strong>
                      </li>
                      <li onClick={() => setOpenInstructions(false)}>
                        <strong>Await Approval</strong>
                      </li>

                      {/* Add more instructions as needed */}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>

        <ReactQuill
          placeholder="Paste in / write content here, remember that Header image, date & author will apear above this content. Aleso keep in mind using (enter) will break the text."
          theme="snow"
          value={text}
          onChange={setText}
          modules={modules}
          formats={formats}
          style={{ height: "30rem" }}
        />
        <div
          className="theme-color-txt"
          style={{
            alignSelf: "flex-start",
            marginTop: "1rem",
            marginLeft: "0.6rem",
            zIndex: "10",
          }}
        >
          Words: {text.trim().split(/\s+/).length}
        </div>
        <ButtonContainer>
          <Button
            style={{
              backgroundColor: "#007bff",
            }}
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
          >
            Preview
          </Button>
          <Auth roles={["admin", "author"]}>
            <Tooltip title="Remember to preview and dubble check the content">
              <Button variant="contained" style={{ backgroundColor: "#1f9245", color: "white", marginLeft: "1rem" }} onClick={handleSubmit}>
                Submit
              </Button>
            </Tooltip>
          </Auth>
        </ButtonContainer>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Article Preview</DialogTitle>
          <DialogContent>
            {imagePreviewUrl && <img src={imagePreviewUrl} alt="Preview" style={{ width: "100%", maxHeight: "20rem" }} />}
            {title && <h1 style={{ textAlign: "center" }}>{title}</h1>}
            {date && <p style={{ textAlign: "center" }}>{date}</p>}
            {author && <p style={{ textAlign: "center" }}>{author}</p>}
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </DialogContent>
        </Dialog>
      </TextAreaContainer>
    </Container>
  );
}

export default CreateArticle;
