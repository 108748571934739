import React from "react";
import PageTable from "./common/PageTable";
import { Row, Col, Table } from "react-bootstrap";
import PageHeader from "./common/PageHeader";
import Pagination from "./common/Pagination";
import { paginate } from "../utils/paginate";
import Dialog from "./common/Dialog";
import { updateSub } from "../services/subService";
import { getReasons } from "../services/reasonService";
import _ from "lodash";
import { toast } from "react-toastify";
import auth from "../services/authService";

class Subs extends PageTable {
  state = {
    reason: 0,
    dialog: {},
    data: [],
    filters: [],
    selectedFilter: "",
    filterName: "product",
    pageSize: 10,
    currentPage: 1,
    sortColumn: {},
    checkboxChecked: false,
    winBackChecked: false,
    searchProperties: ["name", "address", "postCode", "postArea", "email", "tlf"],
    searchQuery: "",
  };

  componentDidMount() {
    const user = auth.getCurrentUser();
    if (user && (user.role === "support" || user.role === "admin")) {
      let { data } = this.state;
      data = this.props.customer.subs;
      this.setState({ data });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const user = auth.getCurrentUser();
    if (user && (user.role === "support" || user.role === "admin")) {
      if (prevProps.customer !== this.props.customer) {
        let { data } = this.state;
        data = this.props.customer.subs;
        this.setState({ data });
      }
    }
  }

  handleDropdownChange = (e) => {
    let { reason } = { ...this.state };
    if (e.target.value) {
      reason = parseInt(e.target.value);
    }
    this.setState({ reason });
  };

  handleCheckboxChange = (e) => {
    this.setState({ checkboxChecked: e.target.checked });
  };

  // winback check handler:
  handleWinBackChange = (e) => {
    this.setState({ winBackChecked: e.target.checked });
  };

  confirm = async (e, id) => {
    e.stopPropagation();

    let { dialog } = this.state;
    dialog.open = true;
    dialog.id = id;
    const reasons = await getReasons();
    dialog.reasons = reasons;
    dialog.title = "Deactivate?";
    dialog.text = "Sure you want to deactivate this subscription?";
    dialog.doYes = this.deactivate;
    dialog.handleDropdownChange = this.handleDropdownChange;
    dialog.handleCheckboxChange = this.handleCheckboxChange;
    dialog.handleWinBackChange = this.handleWinBackChange;

    this.setState({ dialog });
  };

  activateConfirm = async (e, id) => {
    e.stopPropagation();

    let { dialog } = this.state;
    dialog.open = true;
    dialog.id = id;
    dialog.title = "Activate?";
    dialog.text = "Sure you want to activate this subscription?";
    dialog.doYes = this.activate;

    this.setState({ dialog });
  };

  activate = async () => {
    let { dialog, data } = this.state;

    let sub = data.find((x) => x._id === dialog.id);
    const index = data.findIndex((x) => x._id === dialog.id);

    sub.active = true;
    this.setState({ dialog: { ...this.state.dialog, loading: true } });
    sub = await updateSub(sub);

    data[index] = sub;

    dialog = {};
    dialog.open = false;
    this.setState({ dialog, data });
    toast.success("Subscription reactivated.");
    //this.props.updateCustomerSubData(this.props.customer, sub._id, "delete");
  };

  deactivate = async () => {
    let { dialog, data, reason, winBackChecked } = this.state;

    let sub = data.find((x) => x._id === dialog.id);
    const index = data.findIndex((x) => x._id === dialog.id);

    sub.unsubReason = dialog.reasons[reason];
    sub.active = false;
    if (this.state.checkboxChecked) sub.exemptUnsub = true;
    sub.winBackAttempted = winBackChecked;

    this.setState({ dialog: { ...this.state.dialog, loading: true } });

    sub = await updateSub(sub);

    data[index] = sub;

    dialog = {};
    dialog.open = false;
    this.setState({ dialog, data, winBackChecked: false });
    toast.success("Subscription deactivated.");
  };

  closeModal = () => {
    this.setState({ dialog: { open: false } });
  };

  newSub = () => {
    this.props.history.push("/customers/" + this.props.match.params.customerid + "/subs/new");
  };

  editSub = (sub) => {
    this.props.history.push("/customers/" + this.props.match.params.customerid + "/subs/" + sub.row._id);
  };

  render() {
    const { data, pageSize, currentPage, searchQuery, searchProperties, selectedFilter, filterName, sortColumn } = this.state;

    const { length: count } = data;
    const pageName = "Subscription";
    const headers = ["Created", "Updated", "Product", "Deadline", "Active", "Exempt", "Unsub Reason", "Winback Attempted", "Unsub Channel", "Source", ""];

    if (count === 0) return this.renderNoData(pageName);

    let filtered = data;

    filtered = this.applyFilters(filtered, searchQuery, searchProperties, filterName, selectedFilter);

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const paginated = paginate(sorted, currentPage, pageSize);

    return (
      <React.Fragment>
        <Dialog
          open={this.state.dialog.open}
          doNo={this.closeModal}
          doYes={this.state.dialog.doYes}
          title={this.state.dialog.title}
          text={this.state.dialog.text}
          loading={this.state.dialog.loading}
          yes="Yes"
          no="No"
          reasons={this.state.dialog.reasons}
          winBackChecked={this.state.winBackChecked}
          handleDropdownChange={this.handleDropdownChange}
          handleCheckboxChange={this.handleCheckboxChange}
          handleWinBackChange={this.handleWinBackChange}
          checkboxChecked={this.state.checkboxChecked}
        />
        <PageHeader onSearch={this.handleSearch} pageName={pageName} sorted={sorted} count={count} searchQuery={searchQuery} />
        <Row>
          <Col>
            <Table striped bordered hover className="theme-color">
              <thead>
                <tr>
                  {headers.map((header, index) => {
                    return (
                      <th
                        className={header !== "" ? "pointer" : ""}
                        key={index}
                        onClick={() => {
                          if (header !== "") this.handleSort(header.toLowerCase());
                        }}
                      >
                        {header}
                        {header !== "" ? <span> {this.renderSortIcon(header.toLowerCase())}</span> : ""}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {paginated.map((row, index) => {
                  let deadline = null;

                  if (row && row.orders) {
                    for (const key in row.orders) {
                      const order = row.orders[key];

                      if (order.shipped_date) {
                        deadline = new Date(order.shipped_date);
                        if (order.type === "initial") deadline.setDate(deadline.getDate() + row.product.deadline + 1);
                        else deadline.setDate(deadline.getDate() + row.product.regularDeadline + 1);
                      }
                    }
                  }
                  let extraClassName = "";
                  if (row.factoring === "arvato-recurring") {
                    extraClassName = "arvato-recurring";
                  }
                  return (
                    <tr key={index} onClick={() => this.editSub({ row })} className={`pointer ${extraClassName}`}>
                      <td>
                        {row.created &&
                          Intl.DateTimeFormat("no-NO", {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                          }).format(new Date(row.created))}
                      </td>
                      <td>
                        {row.updated &&
                          Intl.DateTimeFormat("no-NO", {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                          }).format(new Date(row.updated))}
                      </td>
                      <td>{row.product.name}</td>
                      <td>
                        {deadline &&
                          Intl.DateTimeFormat("no-NO", {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                          }).format(new Date(deadline))}
                      </td>
                      <td>{row.active && <i className="fas fa-check fa-2x" />}</td>
                      <td>{row.exemptUnsub && row.exemptUnsub.toString()}</td>
                      <td>{row.unsubReason && row.unsubReason.id !== 9999 ? row.unsubReason.reason : row.freeReason}</td>

                      <td>{row.winBackAttempted ? "yes" : "no"}</td>

                      <td>
                        {row.unsubReason && (row.unsubReason.category === "Other" || row.unsubReason.category === "Unsubs" || row.unsubReason.category === "Unsubs_new")
                          ? "Admin unsub"
                          : row.active === false && "Portal unsub"}
                      </td>
                      <td>{row.source?.type}</td>
                      <td>
                        {row.active ? (
                          <button
                            className={deadline ? (new Date() > deadline ? "btn btn-danger" : "btn btn-warning") : "btn btn-warning"}
                            onClick={(e) => this.confirm(e, row._id)}
                          >
                            Deactivate
                          </button>
                        ) : (
                          <button className="btn btn-success" onClick={(e) => this.activateConfirm(e, row._id)}>
                            Activate
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <Pagination itemsCount={sorted.length} pageSize={pageSize} currentPage={currentPage} onPageChange={this.handlePageChange} />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default Subs;
