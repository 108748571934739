import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { getArticles, deleteArticle, getAllProducts, updateArticle } from "../../services/articleService";
import Auth from "../common/Auth";

const Top = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 5px;
  justify-content: space-between;
`;

const Btn = styled(Button)`
  background-color: inherit;
  height: 2rem;
  width: fit-content;
`;
const Controller = styled(FormControl)`
  width: 10rem;
  height: 50px;
  margin-left: 1rem !important;
  margin-right: 1rem !important;
`;

const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 20px;
`;

const ProductCell = styled.div`
  background-color: #595959;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;

  span {
    color: white !important;
  }

  &:hover {
    background-color: #838383;
  }
`;

const CustomTablePagination = styled(TablePagination)`
  .MuiTablePagination-toolbar {
    padding-left: 8px;
  }

  .MuiTablePagination-selectLabel,
  .MuiTablePagination-select,
  .MuiTablePagination-selectIcon,
  .MuiTablePagination-input {
    display: none;
  }

  .MuiTablePagination-actions {
    margin-left: 0;
  }

  p {
    margin: auto;
  }
`;

const ArticleList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [open, setOpen] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState({});
  const [selectedProduct, setSelectedProduct] = useState("");
  const [productNames, setProductNames] = useState([]);
  const [productArticles, setProductArticles] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const productNames = await getAllProducts();
      setProductNames(productNames);
    }
    fetchData();
  }, []);

  const fetchArticles = async (product) => {
    const fetchedArticles = await getArticles();
    const filteredArticles = fetchedArticles.filter((article) => article.product === product);
    setProductArticles(filteredArticles);
  };

  const handleProductSelect = (event) => {
    setSelectedProduct(event.target.value);
    fetchArticles(event.target.value);
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    fetchArticles(product);
  };

  const handleReturnToProductList = () => {
    setSelectedProduct("");
    setProductArticles([]);
  };

  const handleClickOpen = (article) => {
    setToBeDeleted(article);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setToBeDeleted(null);
  };

  const handleDelete = async () => {
    await deleteArticle(toBeDeleted._id);
    setProductArticles(productArticles.filter((article) => article._id !== toBeDeleted._id));
    setOpen(false);
  };

  const approveArticle = async (articleId) => {
    try {
      await updateArticle(articleId, { approved: true });
      setProductArticles((prevArticles) => prevArticles.map((article) => (article._id === articleId ? { ...article, approved: true } : article)));
    } catch (error) {
      console.error("Error approving article:", error);
    }
  };

  const handleSort = (field) => {
    let direction = "asc";
    if (sortField === field && sortDirection === "asc") {
      direction = "desc";
    }
    setSortField(field);
    setSortDirection(direction);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  /*   const handleSearchChange = (event) => {
    setSearch(event.target.value);
  }; */

  const getFlag = (lang) => {
    const languages = [
      { lang: "no", flag: "🇳🇴" },
      { lang: "en", flag: "🇬🇧" },
      { lang: "nl", flag: "🇳🇱" },
      { lang: "sv", flag: "🇸🇪" },
      { lang: "da", flag: "🇩🇰" },
      { lang: "de", flag: "🇩🇪" },
      { lang: "fi", flag: "🇫🇮" },
      { lang: "at", flag: "🇦🇹" },
      { lang: "be", flag: "🇧🇪" },
      { lang: "se", flag: "🇨🇭" },
      { lang: "pt", flag: "🇵🇹" },
      { lang: "fr", flag: "🇫🇷" },
      { lang: "cz", flag: "🇨🇿" },
      { lang: "es", flag: "🇪🇸" },
    ];

    const item = languages.find((item) => item.lang === lang);

    return item ? item.flag : "";
  };

  return (
    <>
      <Paper className="theme-color" style={{ paddingTop: "1rem" }}>
        <Top>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <h2>
              {selectedProduct ? `${selectedProduct} Articles` : "Article Catalogue"} {selectedProduct && `(${productArticles.length})`}
            </h2>
            <Controller variant="outlined">
              <InputLabel>Product</InputLabel>
              <Select className="theme-color" value={selectedProduct} label="Product" onChange={handleProductSelect}>
                {productNames.map((product) => (
                  <MenuItem className="theme-color" value={product} key={product}>
                    {product}
                  </MenuItem>
                ))}
                <MenuItem style={{ backgroundColor: "red", color: "white" }} value="">
                  Show All
                </MenuItem>
              </Select>
            </Controller>

            <Link to="/articles/CreateArticle">
              <Btn style={{ backgroundColor: "#007bff" }} variant="contained" color="primary">
                Add New
              </Btn>
            </Link>

            {selectedProduct && (
              <Btn style={{ marginLeft: "1rem", backgroundColor: "#ff591e" }} variant="contained" onClick={handleReturnToProductList}>
                Return to Product List
              </Btn>
            )}
          </div>
        </Top>
        {selectedProduct ? (
          <>
            <Table className="theme-color">
              <TableHead>
                <TableRow>
                  <TableCell className="theme-color" style={{ textAlign: "left", cursor: "pointer" }} onClick={() => handleSort("productName")}>
                    Product Name
                  </TableCell>
                  <TableCell className="theme-color" style={{ textAlign: "left" }}>
                    Article Name
                  </TableCell>
                  <TableCell className="theme-color" style={{ textAlign: "center" }}>
                    Language / Country
                  </TableCell>
                  <TableCell className="theme-color" style={{ textAlign: "center" }}>
                    Actions
                  </TableCell>
                  <TableCell className="theme-color" style={{ textAlign: "center" }}>
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productArticles
                  .filter((article) => article.title.toLowerCase().includes(search.toLowerCase()))
                  .sort((a, b) => {
                    if (sortField !== null) {
                      if (typeof a[sortField] === "string") {
                        let comparison = a[sortField].localeCompare(b[sortField]);
                        return sortDirection === "desc" ? comparison * -1 : comparison;
                      } else {
                        const aValue = a[sortField];
                        const bValue = b[sortField];
                        let comparison = 0;
                        if (aValue > bValue) {
                          comparison = 1;
                        } else if (aValue < bValue) {
                          comparison = -1;
                        }
                        return sortDirection === "desc" ? comparison * -1 : comparison;
                      }
                    }
                    return 0;
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((article) => (
                    <TableRow key={article._id}>
                      <TableCell className="theme-color">{article.product}</TableCell>
                      <TableCell className="theme-color">{article.title}</TableCell>
                      <TableCell className="theme-color" style={{ textAlign: "center" }}>
                        {article.language} {getFlag(article.language)}
                      </TableCell>
                      <TableCell style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                        <Auth roles={["admin", "author"]}>
                          <Link to={`/articles/CreateArticle?id=${article._id}`}>
                            <Button style={{ backgroundColor: "#007bff" }} startIcon={<Edit />} variant="contained" color="primary">
                              Edit
                            </Button>
                          </Link>
                        </Auth>
                        <Auth roles={["admin", "author"]}>
                          <Button startIcon={<Delete />} variant="contained" color="secondary" onClick={() => handleClickOpen(article)}>
                            Delete
                          </Button>
                        </Auth>
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <Auth roles={["admin", "author"]}>
                          {article.approved ? (
                            <Button variant="outlined" disabled>
                              Approved
                            </Button>
                          ) : (
                            <Button style={{ backgroundColor: "#ff591e" }} variant="contained" onClick={() => approveArticle(article._id)}>
                              Approve
                            </Button>
                          )}
                        </Auth>
                      </TableCell>
                    </TableRow>
                  ))}
                <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                  <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete this article?"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">Once deleted, you will not be able to recover this article.</DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Disagree
                    </Button>
                    <Button onClick={handleDelete} color="primary" autoFocus>
                      Agree
                    </Button>
                  </DialogActions>
                </Dialog>
              </TableBody>
            </Table>
            <CustomTablePagination
              rowsPerPageOptions={[]}
              className="theme-color"
              component="div"
              count={productArticles.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
            />
          </>
        ) : (
          <ProductGrid>
            {productNames.map((product) => (
              <ProductCell key={product} onClick={() => handleProductClick(product)}>
                <span>{product}</span>
              </ProductCell>
            ))}
          </ProductGrid>
        )}
      </Paper>
    </>
  );
};

export default ArticleList;
