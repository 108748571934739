import React from "react";
import styled from "styled-components/macro";

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-top: 10px;
  cursor: pointer;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 25px;
  height: 25px;
  background: ${(props) => (props.checked ? "#1ea58b" : "#d8d8d8")};
  border-radius: 3px;
  transition: all 150ms;
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 21px;
  text-align: center;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px #1ea58b;
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

const Checkbox = ({ className, checked, onChange, id, name, ...props }) => {
  const handleCheckboxClick = (event) => {
    event.preventDefault();

    onChange({
      target: {
        checked: !checked,
        name: name,
      },
    });
  };

  return (
    <CheckboxContainer className={className}>
      <HiddenCheckbox checked={checked} onChange={onChange} id={id} name={name} {...props} />
      <StyledCheckbox checked={checked} onClick={handleCheckboxClick}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
};

export default Checkbox;
