import React, { Component, useEffect, useState, useRef } from "react";
import auth from "../services/authService";
import { getReports } from "../services/reportsService";
import socketIOClient from "socket.io-client";
import { Chart } from "react-google-charts";
import "./dashStyle.css";

import date from "date-and-time";

import runtimeEnv from "@mars/heroku-js-runtime-env";

const env = runtimeEnv();

const DelayedTD = ({ value, className }) => {
  const [renderedValue, setRenderedValue] = useState(value);
  useEffect(() => {
    if (!renderedValue) {
      setRenderedValue(value);
    } else {
      setTimeout(() => {
        setRenderedValue(value);
      }, 500);
    }
  }, [value]);
  return <td className={className}>{renderedValue}</td>;
};

const AnimatedTD = ({ value, className }) => {
  const [classNames, setClassnames] = useState(className);
  const prevCountRef = useRef();
  const animatedClassNames = `${className} dashboard-data-column-animate`;
  if (!prevCountRef.current) prevCountRef.current = value;
  useEffect(() => {
    prevCountRef.current = value;
  }, [value]);

  if (prevCountRef.current !== value && classNames !== animatedClassNames) {
    setClassnames(animatedClassNames);
    setTimeout(() => {
      setClassnames(className);
    }, 500);
  }

  const prevCount = prevCountRef.current;

  return <DelayedTD value={value} className={classNames} />;
};

function createAccuSubChart(accuSubs) {
  let subDataChart = [["Date", "Sub Trend"]];

  for (let i = accuSubs.length - 365; i < accuSubs.length; i++) {
    const data = accuSubs[i];
    subDataChart.push([data.x, data.y]);
  }

  return subDataChart;
}

function createTrailingChart(tailing) {
  let tailingDataChart = [["Date", "Daily Sales"]];

  for (let i = 0; i < tailing.length; i++) {
    const data = tailing[i];
    tailingDataChart.push([data.x, data.y]);
  }

  return tailingDataChart;
}

function createTodayProductPie(home) {
  let productList = cleanUpProductList(home);
  let todayProductPie = [
    ["BioBelly", 0],
    ["Collagen Vital", 0],
    ["Craveless", 0],
    ["Energy B12D3", 0],
    ["Hair And Skin", 0],
    ["Melatonin Vital", 0],
    ["MemRY", 0],
    ["Mory", 0],
    ["SLEEPMORE", 0],
    ["T-Complex", 0],
    ["Collagen Multi", 0],
    ["Mello17", 0],
    ["Retine Optivision", 0],
    ["MEDINIGHT", 0],
    ["hues Glow", 0],
    ["Hues Skin", 0],
    ["Estrodoxin", 0],
    ["Enzytin", 0],
    ["Summit Magnesium", 0],
  ];

  for (let i = 0; i < productList.length; i++) {
    const product = productList[i];
    switch (product.product) {
      case "BioBelly":
        todayProductPie[0][1] += product.ordersToday;
        break;
      case "Collagen Vital":
        todayProductPie[1][1] += product.ordersToday;
        break;
      case "Craveless":
        todayProductPie[2][1] += product.ordersToday;
        break;
      case "Energy B12D3":
        todayProductPie[3][1] += product.ordersToday;
        break;
      case "Hair And Skin":
        todayProductPie[4][1] += product.ordersToday;
        break;
      case "Melatonin Vital":
        todayProductPie[5][1] += product.ordersToday;
        break;
      case "MemRY":
        todayProductPie[6][1] += product.ordersToday;
        break;
      case "Mory":
        todayProductPie[7][1] += product.ordersToday;
        break;
      case "SLEEPMORE":
        todayProductPie[8][1] += product.ordersToday;
        break;
      case "T-Complex":
        todayProductPie[9][1] += product.ordersToday;
        break;
      case "Collagen Multi":
        todayProductPie[10][1] += product.ordersToday;
        break;
      case "Mello17":
        todayProductPie[11][1] += product.ordersToday;
        break;
      case "Retine Optivision":
        todayProductPie[12][1] += product.ordersToday;
        break;
      case "MEDINIGHT":
        todayProductPie[13][1] += product.ordersToday;
        break;
      case "hues Glow":
        todayProductPie[14][1] += product.ordersToday;
        break;
      case "Hues Skin":
        todayProductPie[15][1] += product.ordersToday;
        break;
      case "Estrodoxin":
        todayProductPie[16][1] += product.ordersToday;
        break;
      case "Enzytin":
        todayProductPie[17][1] += product.ordersToday;
        break;
      case "Summit Magnesium":
        todayProductPie[18][1] += product.ordersToday;
        break;
    }
  }

  todayProductPie.sort((a, b) => {
    return b[1] - a[1];
  });

  todayProductPie.unshift(["Product", "Sales Today"]);

  return todayProductPie;
}

function cleanUpProductList(home) {
  let productList = [];
  const dailyProducts = home.orders.products;
  for (let i = 0; i < dailyProducts.length; i++) {
    const product = dailyProducts[i];
    const productName = renameProduct(product.name);
    const country = getFullCountryName(product.country);
    if (productList.length < 1) {
      productList.push({ product: productName, country: country, ordersToday: product.ordersToday });
    } else {
      const index = productList.findIndex((x) => x.country === country && x.product === productName);
      if (index >= 0) {
        productList[index].ordersToday += product.ordersToday;
      } else {
        productList.push({ product: productName, country: country, ordersToday: product.ordersToday });
      }
    }
  }
  return productList;
}

function createTodayCountryPie(home) {
  let productList = cleanUpProductList(home);

  let todayCountryPie = [
    ["Norway", 0],
    ["Sweden", 0],
    ["Denmark", 0],
    ["Netherlands", 0],
    ["Germany", 0],
    ["Austria", 0],
    ["Belgium", 0],
    ["Switzerland", 0],
    ["Finland", 0],
    ["Poland", 0],
    ["Czechia", 0],
    ["France", 0],
    ["Ireland", 0],
    ["Portugal", 0],
    ["Spain", 0],
  ];

  for (let i = 0; i < productList.length; i++) {
    const product = productList[i];
    switch (product.country) {
      case "Norway":
        todayCountryPie[0][1] += product.ordersToday;
        break;
      case "Sweden":
        todayCountryPie[1][1] += product.ordersToday;
        break;
      case "Denmark":
        todayCountryPie[2][1] += product.ordersToday;
        break;
      case "Netherlands":
        todayCountryPie[3][1] += product.ordersToday;
        break;
      case "Germany":
        todayCountryPie[4][1] += product.ordersToday;
        break;
      case "Austria":
        todayCountryPie[5][1] += product.ordersToday;
        break;
      case "Belgium":
        todayCountryPie[6][1] += product.ordersToday;
        break;
      case "Switzerland":
        todayCountryPie[7][1] += product.ordersToday;
        break;
      case "Finland":
        todayCountryPie[8][1] += product.ordersToday;
        break;
      case "Poland":
        todayCountryPie[9][1] += product.ordersToday;
        break;
      case "Czechia":
        todayCountryPie[10][1] += product.ordersToday;
        break;
      case "France":
        todayCountryPie[11][1] += product.ordersToday;
        break;
      case "Ireland":
        todayCountryPie[12][1] += product.ordersToday;
        break;
      case "Portugal":
        todayCountryPie[13][1] += product.ordersToday;
        break;
      case "Spain":
        todayCountryPie[14][1] += product.ordersToday;
        break;
    }
  }

  todayCountryPie.sort((a, b) => {
    return b[1] - a[1];
  });

  todayCountryPie.unshift(["Country", "Sales Today"]);

  return todayCountryPie;
}

function renameProduct(product) {
  let productName = "";
  if (product.includes("BioBelly")) productName = "BioBelly";
  else if (product.includes("Collagen Vital")) productName = "Collagen Vital";
  else if (product.includes("Craveless")) productName = "Craveless";
  else if (product.includes("Energy B12D3")) productName = "Energy B12D3";
  else if (product.includes("Hair And Skin")) productName = "Hair And Skin";
  else if (product.includes("Melatonin Vital")) productName = "Melatonin Vital";
  else if (product.includes("MemRY")) productName = "MemRY";
  else if (product.includes("Mory")) productName = "Mory";
  else if (product.includes("ProbiForte")) productName = "BioBelly";
  else if (product.includes("SLEEPMORE")) productName = "SLEEPMORE";
  else if (product.includes("T-Complex")) productName = "T-Complex";
  else if (product.includes("Collagen Multi")) productName = "Collagen Multi";
  else if (product.includes("Mello17")) productName = "Mello17";
  else if (product.includes("Retine")) productName = "Retine Optivision";
  else if (product.includes("MEDINIGHT")) productName = "MEDINIGHT";
  else if (product.includes("hues Glow")) productName = "hues Glow";
  else if (product.includes("Hues Skin")) productName = "Hues Skin";
  else if (product.includes("Estrodoxin")) productName = "Estrodoxin";
  else if (product.includes("Enzytin")) productName = "Enzytin";
  else if (product.includes("Summit Magnesium")) productName = "Summit Magnesium";
  return productName;
}

function getFullCountryName(countryShort) {
  switch (countryShort) {
    case "no":
      return "Norway";
    case "se":
      return "Sweden";
    case "dk":
      return "Denmark";
    case "fi":
      return "Finland";
    case "nl":
      return "Netherlands";
    case "de":
      return "Germany";
    case "at":
      return "Austria";
    case "be":
      return "Belgium";
    case "ch":
      return "Switzerland";
    case "pl":
      return "Poland";
    case "cz":
      return "Czechia";
    case "fr":
      return "France";
    case "ie":
      return "Ireland";
    case "pt":
      return "Portugal";
    case "es":
      return "Spain";
  }
}

class Dash extends Component {
  state = {};

  async componentDidMount() {
    const user = auth.getCurrentUser();

    const socket = socketIOClient(env.REACT_APP_API, {
      path: "/socket.io-client",
      transports: ["websocket"],
    });
    socket.on("report", async (report) => {
      let home = report.home;
      for (const trailKey in home.trailData) {
        home.trailData[trailKey].x = new Date(home.trailData[trailKey].x);
      }
      for (const chartKey in home.chartData) {
        home.chartData[chartKey].x = new Date(home.chartData[chartKey].x);
      }
      for (const accuKey in home.accuSubs) {
        home.accuSubs[accuKey].x = new Date(home.accuSubs[accuKey].x);
      }
      for (const subKey in home.subData) {
        home.subData[subKey].x = new Date(home.subData[subKey].x);
      }
      for (const avgKey in home.thisMonthAvg) {
        home.thisMonthAvg[avgKey].x = new Date(home.thisMonthAvg[avgKey].x);
      }
      for (const labelKey in home.thisMonthLabels) {
        home.thisMonthLabels[labelKey].x = new Date(home.thisMonthLabels[labelKey].x);
      }
      this.setState({ home });
    });

    getReports().then(async (home) => {
      for (const trailKey in home.trailData) {
        home.trailData[trailKey].x = new Date(home.trailData[trailKey].x);
      }
      for (const chartKey in home.chartData) {
        home.chartData[chartKey].x = new Date(home.chartData[chartKey].x);
      }
      for (const accuKey in home.accuSubs) {
        home.accuSubs[accuKey].x = new Date(home.accuSubs[accuKey].x);
      }
      for (const subKey in home.subData) {
        home.subData[subKey].x = new Date(home.subData[subKey].x);
      }
      for (const avgKey in home.thisMonthAvg) {
        home.thisMonthAvg[avgKey].x = new Date(home.thisMonthAvg[avgKey].x);
      }
      for (const labelKey in home.thisMonthLabels) {
        home.thisMonthLabels[labelKey].x = new Date(home.thisMonthLabels[labelKey].x);
      }
      this.setState({ user, home });
    });
  }

  render() {
    if (this.state.user?.role === "admin" || this.state.user?.role === "home") {
      const home = this.state.home;

      console.log(home);

      const todayCountryPie = createTodayCountryPie(home);
      const todayProductPie = createTodayProductPie(home);
      const accuSubsLineChart = createAccuSubChart(home.accuSubs);
      const trailingChart = createTrailingChart(home.trailData);

      return (
        <div className="dash-container">
          <div className="fullrow textcenter">
            <div className="semitext nopadding">NL Subs</div>
          </div>
          <div className="fullrow justcenter fivelayout">
            <table className="std-table textcenter">
              <thead>
                <tr>
                  <th>{todayCountryPie[0][0]}</th>
                  <th>{todayCountryPie[0][1]}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{todayCountryPie[1][0]}</td>
                  <strong>
                    <AnimatedTD value={todayCountryPie[1][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayCountryPie[2][0]}</td>
                  <strong>
                    <AnimatedTD value={todayCountryPie[2][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayCountryPie[3][0]}</td>
                  <strong>
                    <AnimatedTD value={todayCountryPie[3][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayCountryPie[4][0]}</td>
                  <strong>
                    <AnimatedTD value={todayCountryPie[4][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayCountryPie[5][0]}</td>
                  <strong>
                    <AnimatedTD value={todayCountryPie[5][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayCountryPie[6][0]}</td>
                  <strong>
                    <AnimatedTD value={todayCountryPie[6][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayCountryPie[7][0]}</td>
                  <strong>
                    <AnimatedTD value={todayCountryPie[7][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayCountryPie[8][0]}</td>
                  <strong>
                    <AnimatedTD value={todayCountryPie[8][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayCountryPie[9][0]}</td>
                  <strong>
                    <AnimatedTD value={todayCountryPie[9][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayCountryPie[10][0]}</td>
                  <strong>
                    <AnimatedTD value={todayCountryPie[10][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayCountryPie[11][0]}</td>
                  <strong>
                    <AnimatedTD value={todayCountryPie[11][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayCountryPie[12][0]}</td>
                  <strong>
                    <AnimatedTD value={todayCountryPie[12][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayCountryPie[13][0]}</td>
                  <strong>
                    <AnimatedTD value={todayCountryPie[13][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayCountryPie[14][0]}</td>
                  <strong>
                    <AnimatedTD value={todayCountryPie[14][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayCountryPie[15][0]}</td>
                  <strong>
                    <AnimatedTD value={todayCountryPie[15][1]} />
                  </strong>
                </tr>
              </tbody>
            </table>
            <Chart chartType="PieChart" data={todayCountryPie} options={{ legend: "none", pieSliceText: "label" }} height={"400px"} />
            <div className="textcenter">
              <table className="center-table">
                <tr>
                  <AnimatedTD className="largetext strong" value={home.orders.ordersToday} />
                </tr>
                <tr>
                  <td className="smalltext">Yesterday</td>
                </tr>
                <tr>
                  <AnimatedTD className="smalltext strong" value={home.orders.ordersYesterday} />
                </tr>
                <tr>
                  <td className="smalltext">This Month</td>
                </tr>
                <tr>
                  <AnimatedTD className="smalltext strong" value={home.orders.ordersMonth} />
                </tr>
                <tr>
                  <td className="smalltext">Active Subs</td>
                </tr>
                <tr>
                  <AnimatedTD className="smalltext strong" value={home.orders.activeSubs} />
                </tr>
              </table>
            </div>
            <Chart chartType="PieChart" data={todayProductPie} options={{ legend: "none", pieSliceText: "label" }} height={"400px"} />
            <table className="std-table textcenter">
              <thead>
                <tr>
                  <th>{todayProductPie[0][0]}</th>
                  <th>{todayProductPie[0][1]}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{todayProductPie[1][0]}</td>
                  <strong>
                    <AnimatedTD value={todayProductPie[1][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayProductPie[2][0]}</td>
                  <strong>
                    <AnimatedTD value={todayProductPie[2][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayProductPie[3][0]}</td>
                  <strong>
                    <AnimatedTD value={todayProductPie[3][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayProductPie[4][0]}</td>
                  <strong>
                    <AnimatedTD value={todayProductPie[4][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayProductPie[5][0]}</td>
                  <strong>
                    <AnimatedTD value={todayProductPie[5][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayProductPie[6][0]}</td>
                  <strong>
                    <AnimatedTD value={todayProductPie[6][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayProductPie[7][0]}</td>
                  <strong>
                    <AnimatedTD value={todayProductPie[7][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayProductPie[8][0]}</td>
                  <strong>
                    <AnimatedTD value={todayProductPie[8][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayProductPie[9][0]}</td>
                  <strong>
                    <AnimatedTD value={todayProductPie[9][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayProductPie[10][0]}</td>
                  <strong>
                    <AnimatedTD value={todayProductPie[10][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayProductPie[11][0]}</td>
                  <strong>
                    <AnimatedTD value={todayProductPie[11][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayProductPie[12][0]}</td>
                  <strong>
                    <AnimatedTD value={todayProductPie[12][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayProductPie[13][0]}</td>
                  <strong>
                    <AnimatedTD value={todayProductPie[13][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayProductPie[14][0]}</td>
                  <strong>
                    <AnimatedTD value={todayProductPie[14][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayProductPie[15][0]}</td>
                  <strong>
                    <AnimatedTD value={todayProductPie[15][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayProductPie[16][0]}</td>
                  <strong>
                    <AnimatedTD value={todayProductPie[16][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayProductPie[17][0]}</td>
                  <strong>
                    <AnimatedTD value={todayProductPie[17][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayProductPie[18][0]}</td>
                  <strong>
                    <AnimatedTD value={todayProductPie[18][1]} />
                  </strong>
                </tr>
                <tr>
                  <td>{todayProductPie[19][0]}</td>
                  <strong>
                    <AnimatedTD value={todayProductPie[19][1]} />
                  </strong>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="fullrow halfgrid topspacer">
            <div className="lefthalf">
              <Chart chartType="Bar" data={trailingChart} height={"400px"} options={{ chart: { title: "Trailing 30 days" } }} />
            </div>
            <div className="righthalf">
              <Chart chartType="Line" data={accuSubsLineChart} height={"400px"} options={{ chart: { title: "Trailing year" } }} />
            </div>
          </div>
        </div>
      );
    } else {
      return "Access denied";
    }
  }
}

export default Dash;
